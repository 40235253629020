@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");

* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
}

a:hover {
  color: rgb(21, 169, 176);
}

li {
  list-style: none;
}

/* header styling */
.main-header {
  background-color: rgba(97, 138, 200, 0.9);
  position: fixed;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  z-index: 2;
  align-items: center;
  /* border: solid red; */
}

.main-nav {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.main-nav ul {
  display: flex;
  align-items: center;
  padding: 10px;
  list-style: none;

  /* border: solid blue; */
  width: 100%;
}
.main-nav .links {
  justify-content: center;
  /* align-items: center; */
}
.icons {
  justify-content: flex-end;
  margin-right: 2rem;
  gap: 2rem;
}

.main-nav li > a {
  text-decoration: none;
  font-weight: bold;
  color: white;
  white-space: nowrap;
}

.main-nav li > a:hover {
  color: rgb(21, 169, 176);
}

.main-nav li {
  font-size: 2rem;
}

.header-logo {
  height: auto;
  width: 120px;
  margin-top: 7px;
}

.header-logo-main {
  height: auto;
  width: 160px;
  margin-top: 7px;
}
.sticky {
  width: 100%;
  background: linear-gradient(rgb(97, 138, 200), rgba(97, 138, 200, 0.9));
}

/* new drop */
.drop {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-menu ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  text-align: start;
  gap: 1rem;
  padding: 1rem;
}

/* drop down */

.dropUl {
  width: 100%;
  margin-left: 2rem;
}

.dropdown,
.hamburger {
  display: none;
}

.link {
  background: none;
  border: none;
  text-decoration: none;
  color: white;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  font-size: 3rem;
}

.dropdown {
  position: relative;
  list-style: none;
}

.btn {
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  text-align: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 15px 32px;
  border: 2px solid white;
}
.btn:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.75);
  text-shadow: none;
}
/* dropdown content */
.dropdownLinks {
  display: none;
  position: absolute;
  padding: 2rem;
  background-color: rgb(97, 138, 200);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 3px;
}

.dropdownLinks a {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2rem;
  font-size: 2.5rem;
}
.dropdownLinks a:hover {
  color: rgb(21, 169, 176);
}

.dropdown:hover .dropdownLinks {
  display: block;
}
.dropdown:hover .link {
  color: rgb(21, 169, 176);
}

/* end hamburger and dropdown */

/* home page */

.home-div {
  background-image: url("./images/flowers.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70vw;
  text-align: center;
  gap: 30px;
}

.home-div h1 {
  font-size: 5rem;
  color: white;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.3);
}

.home-div a {
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  border: 2px solid white;
}

.home-div a:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.75);
  text-shadow: none;
}

/* body */
.div {
  background-color: rgb(218, 209, 209);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
}

.inner-div {
  width: 60vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privacy {
  display: flex;
  width: 60vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  padding-top: 60px;
}

h2,
h3 {
  color: rgba(0, 0, 0, 0.75);
}

h2 {
  font-size: 5rem;
}

.privacy h2 {
  font-size: 3rem;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  font-size: 2rem;
  line-height: 3rem;
  color: rgba(0, 0, 0, 0.6);
}
p {
  font-size: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
}

.privacy p {
  font-size: 2rem;
}
/* About */

.about-div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.about-div h3 {
  margin-bottom: 2rem;
}
.img-div {
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 60px;
}
.bio-img {
  display: block;
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 50%;
}
.bio {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: left;
}
.bio li {
  margin-bottom: 1rem;
}
.bio a {
  text-decoration: underline;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);
}
.bio a:hover {
  color: rgb(21, 169, 176);
}

/* start */
.inner-div.start-div {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 50px;
}

.start-div > div {
  width: 50%;
}

.kid-craft {
  flex-shrink: 1;
  width: 100%;
  border-radius: 5px;
  display: block;
}
.center {
  width: 100%;
  text-align: center;
}

.next-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.next-div > div {
  max-width: 47%;
}
.inner-div.next h2 {
  margin: auto;
}

/* consulation component*/
.text-link {
  font-style: italic;
  font-weight: bold;
}

.consultation {
  padding-top: 100px;
}

.con-div li {
  list-style: circle;
  font-size: 2.5rem;
  line-height: 3.5rem;
}
.con-div ul {
  padding-left: 20px;
}

/* Calendly */
.calendly-div {
  background-color: rgb(218, 209, 209);
}

/* resources */
.start,
.resources {
  padding-top: 100px;
}
.resource-div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.resource-div > div {
  display: flex;
  flex-direction: column;
  max-width: 30%;
}
.resource-ul {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 1.5rem;
  line-height: 2.4rem;
  margin: 0;
}

h3 {
  font-size: 3rem;
}
.resource-div li {
  font-size: 2rem;
}

.ems {
  font-size: 2rem;
}
/* contact page */
.contactPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 2rem;
}

.contactDiv h3 {
  margin-top: 0;
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.contactDiv ul {
  list-style: none;
  padding-left: 0;
}

.contactDiv li {
  margin-bottom: 2rem;
}

.contactDiv a {
  text-decoration: none;
  color: #000000;
}

.contactDiv a:hover {
  color: #adadad;
}

.contactDiv i {
  font-size: 2.5rem;
  padding-right: 15px;
}

/* formerly contactPage section */
.contact {
  width: 60vw;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.contact-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

/* form */

fieldset {
  padding: 0;
  border: none;
  display: flex;
  justify-content: space-between;
}

.contactPage fieldset div {
  width: 49%;
}

.buttonDiv {
  display: flex;
  justify-content: center;
}
.submit-button {
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  width: 100%;
  font-weight: bold;
  padding: 12px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  border: 2px solid white;
}

.submit-button:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.75);
  text-shadow: none;
}

.form-group {
  margin-bottom: 2rem;
}

.form-control {
  font-size: 2rem;
  display: block;
  width: 100%;
  height: 3rem;
  padding: 0.75rem 1rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-textarea {
  min-height: 120px;
  width: 100%;
  resize: vertical;
  border-radius: 5px;
  font-size: 2rem;
  font-family: inherit;
}

.returnMessage {
  font-size: 3.5rem;
}

/* footer */

footer {
  padding-top: 40px;
  width: 100%;
  background-color: rgba(97, 138, 200, 0.7);
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
footer p {
  color: inherit;
  margin-top: 0;
}

footer li,
footer a {
  color: white;
}
footer a:hover {
  color: rgb(21, 169, 176);
}

.disclaimer p {
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
  font-style: italic;
}
.disclaimer {
  padding: 20px;
  width: 100%;
  text-align: center;
}

.footer-container {
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 30px;
}
.footer-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-div h4 {
  margin-bottom: 6px;
  font-size: 2rem;
  border-bottom: 1px solid white;
}
.footer-div ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  gap: 1rem;
  font-size: 1.5rem;
}
.footer-div li {
  font-size: 2rem;
}

.footer-div span {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.copyright p {
  font-size: 1.3rem;
}
.button-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-div button {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.4);
  color: white;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 3rem;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  border: 2px solid white;
}

.button-div button:hover {
  background-color: white;
  color: rgba(0, 0, 0, 0.75);
  text-shadow: none;
}

/* Slideshow */
.slideshow-container {
  width: 100%;
  background-color: rgb(218, 209, 209);
  padding: 30px 0;
}

.slideshow {
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1500ms;
}

.slide {
  display: inline-block;

  height: 500px;
  width: 100%;
  border-radius: 20px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: rgb(21, 169, 176);
}
/* testimonials */
.inner-div.test-div {
  align-items: center;
}
.testimonial {
  position: relative;
  line-height: 3.5rem;
  padding: 30px;
  height: auto;
  width: 700px;
  margin: 1em 0 3em;
  color: #000;
  background: rgb(21, 169, 176);
  border-radius: 40px;
}
.testimonial::after {
  content: "";
  display: block; /* reduce the damage in FF3.0 */
  position: absolute;
  bottom: -15px;
  left: 50px;
  width: 0;
  border-width: 15px 15px 0;
  border-style: solid;
  border-color: rgb(21, 169, 176) transparent;
}
.quote {
  color: rgba(0, 0, 0, 0.6);
  font-size: 4rem;
}
.quoteL,
.quoteR {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.quoteR {
  justify-content: flex-end;
}

@media screen and (max-width: 1400px) {
  .bio-img {
    width: 290px;
    height: 290px;
  }
}
@media screen and (max-width: 1150px) {
  .contact {
    justify-content: center;
    gap: 3rem;
  }
}

@media screen and (max-width: 1000px) {
  .testimonial {
    width: 500px;
  }
  .contact,
  .inner-div {
    width: 70vw;
  }
}
@media screen and (max-width: 1100px) {
  .testimonial {
    width: 400px;
  }
  .dropdown,
  .hamburger {
    display: block;
  }
  .links-li {
    display: none;
  }
  .inner-div.start-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .start-div > div {
    width: 100%;
  }

  .next-div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .next-div > div {
    max-width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .footer-div li {
    font-size: 1.5rem;
  }
  .testimonial {
    width: 340px;
  }
  .about-div {
    flex-direction: column;
  }
  .img-div {
    justify-content: center;
  }
  .bio-img {
    width: 300px;
    height: 300px;
  }
  .img-div,
  .bio {
    width: 100%;
  }
  .img-div {
    padding-top: 0;
  }
  .resource-div h3 {
    font-size: 2.5rem;
    height: 4rem;
  }
  .resource-div li {
    font-size: 2rem;
  }
  .home-div h1 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 660px) {
  .form-control,
  .input-textarea {
    font-size: 1.7rem;
  }

  .main-nav ul {
    gap: 2rem;
  }
  .icons {
    gap: 1rem;
  }
  .resource-div {
    flex-direction: column;
    align-items: center;
  }
  .resource-div h3 {
    font-size: 2.5rem;
    height: 2.5rem;
  }
  .resource-div li {
    font-size: 2rem;
  }
  .resource-div > div {
    max-width: 100%;
    width: 100%;
    align-items: center;
  }
  .inner-div {
    width: 85vw;
  }
}

@media (min-width: 400px) {
  .main-nav .links {
    gap: 3rem;
  }
}

@media (min-width: 1150px) {
  .main-nav .links {
    gap: 3rem;
  }
}

@media (min-width: 1250px) {
  .main-nav .links {
    gap: 6rem;
  }
}

@media (min-width: 1400px) {
  .main-nav .links {
    gap: 9rem;
  }
}
